<template>
  <div class="dsn-nav-bar">
    <div class="site-header">
      <div class="extend-container">
        <div class="inner-header">
          <div class="main-logo scale">
            <router-link to="/">
              <img :src="require('@/assets/img/logo_en.png')" alt="logo" />
            </router-link>
          </div>
        </div>

        <nav class="accent-menu main-navigation">
          <ul class="extend-container">
            <NavLinks
              v-for="(link, index) in links"
              :key="index"
              :index="index"
              :id="link.id"
              :name="link.name"
              :url="link.url"
              :type="link.type"
              :urlList="link.urlList"
              :isHumburger="hamburger"
            />
          </ul>
        </nav>
      </div>
    </div>
    <div class="header-top">
      <div class="header-container">
        <div class="logo main-logo scale">
          <router-link to="/">
            <img :src="require('@/assets/img/logo_en.png')" alt="logo" />
          </router-link>
        </div>

        <div
          ref="menuIcon"
          class="menu-icon"
          @click="navToogle"
          @mouseover="mouseHover"
          @mouseleave="mouseLeave()"
        >
          <div class="icon-m">
            <i class="menu-icon-close fas fa-times"></i>
            <span class="menu-icon__line menu-icon__line-left"></span>
            <span class="menu-icon__line"></span>
            <span class="menu-icon__line menu-icon__line-right"></span>
          </div>

          <div class="text-menu">
            <div class="text-button" v-show="mMenu === true">
              <span data-splitting="chars">Menu</span>
            </div>
            <div class="text-open" v-show="mOpen === true">
              <span data-splitting="chars">Open</span>
            </div>
            <div class="text-close" v-show="mClose === true">
              <span data-splitting="chars">Close</span>
            </div>
          </div>
        </div>

        <div class="nav">
          <div class="inner">
            <div class="nav__content"></div>
          </div>
        </div>
        <div class="nav-content">
          <div class="inner-content">
            <address class="container-content d-flex flex-column justify-content-center">
              <div class="nav__info pt-3">
                <div class="nav-data pt-3">
                  <h5 class="mb-10">Studio</h5>
                  <p>
                    台北市八德路一段23號9樓<br />
                    9F., No. 23, Sec. 1, Bade Rd., Zhongzheng Dist., <br />Taipei City
                    100410 , Taiwan (R.O.C.)
                  </p>
                </div>
                <div class="nav-data mt-30">
                  <h5 class="mb-10">Contact</h5>
                  <p class="links mb-1">
                    <a class="link-hover" href="tel:+886 (2)7751898">+886 2-77518986</a>
                  </p>
                  <p class="links">
                    <a class="link-hover" href="mailto:business-partner@nbd-ai.com"
                      >business-partner@nbd-ai.com</a
                    >
                  </p>
                </div>
              </div>
              <!-- <div class="nav-social nav-data pt-30 pb-30">
                <div class="nav-social-inner p-relative">
                  <h5 class="mb-10">Follow us</h5>
                  <ul>
                    <li>
                      <a href="#" target="_blank" rel="nofollow noopener noreferrer"
                        >Dribbble.</a
                      >
                    </li>
                    <li>
                      <a href="#" target="_blank" rel="nofollow noopener noreferrer"
                        >Behance.</a
                      >
                    </li>
                    <li>
                      <a href="#" target="_blank" rel="nofollow noopener noreferrer"
                        >Linkedin.</a
                      >
                    </li>
                    <li>
                      <a href="#" target="_blank" rel="nofollow noopener noreferrer"
                        >Twitter.</a
                      >
                    </li>
                  </ul>
                </div>
              </div> -->
            </address>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import links from "./nav.json";
import { onMounted, onUnmounted } from "vue";
import { navButton, hamburgerMenu } from "@/assets/global/global";
import Splitting from "splitting";
import NavLinks from "@/components/navbar/NavLinks.vue";

const hamburger = true;
const navToogle = () => navButton();
const changeClass = () => {
  Splitting();
  const textMenu = document.querySelector(".text-menu");
  const words = textMenu.querySelectorAll(".word");
  words.forEach((word) => {
    word.classList.add("btnChars");
    word.classList.remove("word");
    const Chars = word.querySelectorAll(".char");
    Chars.forEach((char, index) => {
      char.classList.add(`char-${index + 1}`);
    });
  });
};

onMounted(() => {
  hamburgerMenu(hamburger);
  changeClass();
});
onUnmounted(() => {});
</script>

<script>
export default {
  data() {
    return {
      mMenu: true,
      mOpen: false,
      mClose: false,
    };
  },
  methods: {
    mouseHover() {
      const menuState = document.querySelector("body");
      this.mMenu = false;
      this.mClose = menuState.classList.contains("nav-active");
      this.mOpen = !this.mClose;
    },
    mouseLeave() {
      this.mMenu = true;
      this.mOpen = false;
      this.mClose = false;
    },
  },
  unmounted() {
    Splitting.kill;
  },
};
</script>

<style lang="scss">
@import "@/assets/sass/mixins/_mixins-master.scss";
@import "@/assets/sass/mixins/_utilities.scss";

/** === Main menu === */
.dsn-nav-bar {
  @include text-overlay-hide(0, 1, ".icon-m", ".menu-icon-close");
  @include text-overlay-show(0, 3, ".icon-m", ".menu-icon__line");

  body.nav-active & {
    @include text-overlay-show(1000, 1, ".icon-m", ".menu-icon-close");
    @include text-overlay-hide(0, 3, ".icon-m", ".menu-icon__line");
  }
}

.dsn-nav-bar {
  > .header-top > .header-container,
  .site-header {
    transition: 0.5s ease-in-out;
  }

  &.nav-bg {
    > .header-top > .header-container,
    .site-header {
      padding-bottom: 15px;
      padding-top: 15px;
      background-color: #000;
    }
  }

  &.hide-nave {
    > .header-top > .header-container,
    .site-header {
      transform: translateY(-100px);
    }
  }
}

.site-header {
  display: block;
  position: fixed;
  width: 100%;
  padding-top: 30px;
  padding-bottom: 20px;
  z-index: 9000;
  -webkit-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  top: 0;

  @include media_991() {
    display: none;
  }

  &.header-stickytop {
    height: auto !important;
  }

  .extend-container {
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    padding: 0 50px;
    max-width: none;
    margin: 0 auto;

    .inner-header {
      position: relative;
      margin: 0 auto;

      .main-logo {
        width: 96px;
        padding-top: 4px;
        position: relative;
        z-index: 9999;
        -webkit-transition: width 0.5s ease-in-out 0s;
        -o-transition: width 0.5s ease-in-out 0s;
        transition: width 0.5s ease-in-out 0s;
      }
    }

    .main-navigation {
      position: relative;
      background: transparent !important;
      height: 0%;
      width: 90%;
      right: 0;
      top: 0;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: flex-start;
      align-items: flex-start;
      -webkit-transition: background-color 0.5s ease-in-out 0.5s,
        height 0.5s ease-in-out 0.5s;
      -o-transition: background-color 0.5s ease-in-out 0.5s, height 0.5s ease-in-out 0.5s;
      transition: background-color 0.5s ease-in-out 0.5s, height 0.5s ease-in-out 0.5s;

      ul {
        width: 100%;
        max-width: none;
        margin: 0;
        display: -ms-flexbox;
        display: flex;
        padding: 0;
        -ms-flex-pack: end;
        justify-content: flex-end;

        li {
          color: #fff;
          font-size: 13px;
          font-weight: 400;
          line-height: 1;
          padding-right: 25px;
          padding-top: 12px;
          position: relative;
          text-align: center;

          &:last-child {
            padding-bottom: 5px;
          }

          button,
          a {
            -webkit-transform: translate(0);
            -ms-transform: translate(0);
            transform: translate(0);
            text-transform: uppercase;
            letter-spacing: 2px;
            position: relative;
            padding: 10px 0;
            margin-top: -10px;
            animation: shine 1s infinite;
            line-height: 15px;

            &:hover {
              -webkit-mask-image: linear-gradient(
                -75deg,
                rgba(0, 0, 0, 0.6) 30%,
                #000 50%,
                rgba(0, 0, 0, 0.6) 70%
              );
              mask-image: linear-gradient(
                -75deg,
                rgba(0, 0, 0, 0.6) 30%,
                #000 50%,
                rgba(0, 0, 0, 0.6) 70%
              );
              -webkit-mask-size: 200%;
              animation: shine 1s infinite;
              color: inherit;
              mask-size: 200%;
              animation: shine 1s infinite;
              color: inherit;
            }
          }

          ul {
            transform: translate3d(0px, 20px, 0px);
            display: block;
            opacity: 0;
            min-width: 200px;
            position: absolute;
            width: 100%;
            left: -20px;
            height: inherit;
            overflow: visible;
            visibility: hidden;
            top: 40px;
            padding-top: 20px;
            padding-bottom: 10px;
            background: #000;
            border-radius: 3px;
            transition: all 0.7s ease-in-out;

            li {
              overflow: visible;
              position: relative;
              list-style: none;
              text-align: left;
              float: left;
              display: block;
              padding-left: 20px;
              width: 100%;

              button,
              a {
                line-height: 20px;
              }
            }
          }
        }
      }

      ul.extend-container > li {
        padding-right: 30px;
        font-weight: 600;

        &:last-child {
          padding-right: 0;
        }
      }

      .custom-drop-down {
        ul {
          ul {
            transform: translate3d(100%, 0px, 0px);
            padding: 0 0 0 0px;
            z-index: 1;
            top: 0 !important;

            li {
              border-left: 1px solid var(--border-color);
            }
          }
        }

        &:hover {
          > ul {
            opacity: 1;
            visibility: visible;
            top: 15px;
          }
        }
      }
    }
  }
}

.site-header ul li a,
.site-header ul li button {
  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    width: 0;
    height: 3px;
    background-color: #212121;
    @include transition(0.5s);
  }
}

@media (min-width: 991px) {
  .nav-mobile {
    visibility: hidden;
  }
}

/** === Hamburger menu === */
body.hamburger-menu {
  .site-header {
    display: none;
  }

  .header-top {
    display: block;
  }
}

.dsn-nav-bar {
  position: absolute;
  z-index: 999;
  width: 100%;
}

.header-top {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  display: none;

  @include transition(all 0.5s);

  @include media_991() {
    display: block;
  }

  &.header-stickytop {
    z-index: 999;
  }

  .header-container {
    position: absolute;
    width: 100%;
    max-width: 2560px;
    padding: 22px 50px;
    margin: auto;

    @include media_768() {
      padding: 20px 30px;
    }

    @include media_575() {
      padding: 20px 15px;
    }

    .logo {
      position: relative;
      display: table;
      pointer-events: auto;
      float: left;
      z-index: 99999;
      top: 5px;

      &.dark_logo {
        display: none;
      }

      img {
        width: 200px;
      }
    }

    .menu-icon {
      $size: 22px;
      position: relative;
      z-index: 999;
      float: right;
      top: 14px;
      cursor: pointer;
      margin-right: 50px;

      .icon-circle {
        position: absolute;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        box-sizing: border-box;
        border: 2px solid #fff;
        left: 50%;
        top: 50%;
        margin-top: -23px;
        margin-left: -21px;
        cursor: pointer;
        z-index: 4;
        opacity: 0;
        visibility: hidden;
        @include transition(opacity 0.5s);
      }

      &:hover .icon-circle {
        opacity: 0.2;
        visibility: visible;
      }

      .text-menu {
        position: relative;
        display: inline-block;
        margin-left: 10px;
        font-family: var(--heading-font);
        font-weight: 600;
        top: -21px;
        color: #fff;
        text-transform: uppercase;
        opacity: 0.9;

        > div {
          position: absolute;
          left: 0;
          top: 0;
          width: max-content;
        }

        .text-open {
          @include text-overlay-hide(0, 4);
        }

        .text-close {
          @include text-overlay-hide(0, 4);
        }
      }

      .icon-m {
        position: relative;
        width: 22px;
        display: inline-block;
        height: 18px;
      }

      .menu-icon-close {
        position: absolute;
        font-size: 20px;
        text-align: center;
        top: 0;
        color: #fff;
      }

      &__line {
        height: 2px;
        width: 22px;
        display: block;
        background-color: #fff;
        margin-bottom: 4px;
        transition: transform 0.2s ease, background-color 0.5s ease;
      }

      &:hover {
        .menu-icon__line-left {
          transform: translateY(6px);
        }

        .menu-icon__line-right {
          transform: translateY(-6px);
        }
      }

      &__line-left {
        width: calc($size / 2);
        transition: transform 0.5s ease-in-out !important;
      }

      &__line-right {
        width: calc($size / 2);
        position: absolute;
        right: 2px;
      }
    }

    & .menu-icon:hover .text-menu {
      .text-button {
        @include text-overlay-hide(0, 4);
      }
    }

    body.nav-active & {
      .menu-icon .text-menu {
        top: -17px;

        .text-open {
          @include text-overlay-hide(0, 4);
        }
      }

      .menu-icon:hover .text-menu {
        .text-button {
          @include text-overlay-hide(0, 4);
        }
      }
    }
  }
}

.nav {
  position: absolute;
  $width: 60%;
  $height: 100vh;
  width: $width;
  height: $height;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  text-align: center;
  transition: all 1s ease-in-out 0.8s;
  z-index: 15;

  &:before,
  &:after {
    content: "";
    position: fixed;
    width: $width;
    height: $height;
    left: 0;
    top: 0;
    background: #000;
    z-index: -1;
    transition: transform cubic-bezier(0.77, 0, 0.175, 1) 1.3s;
    transform: translateX(-100%) translateY(0%);
  }

  @include media_768 {
    &,
    &:before,
    &:after {
      width: 100%;
    }

    .nav__list-item a,
    .nav__list-item button {
      width: 100% !important;
      padding-left: 0;
      padding-bottom: 10px;
      text-align: center;
    }
  }

  &:after {
    background: #000;
    transition-delay: 0.1s;
  }

  &:before {
    transition-delay: 0.8s;
  }

  .inner {
    display: table-cell;
    vertical-align: middle;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 100vw;
    margin: auto;
    opacity: 0;
    transition: all 0.2s ease;
    height: 100vh;

    .logo {
      position: absolute;
      top: 9px;
      left: 78px;
      pointer-events: auto;
      padding-top: 18px;

      img {
        width: 80px;
        height: 50px;
      }
    }
  }

  &__content {
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
    -webkit-box-pack: center;
    justify-content: center;
    align-items: center;
    height: 100%;
    text-align: left;
  }

  &__list {
    width: 100%;
    overflow: auto;
    padding-top: 50px;
  }

  &__list-item {
    position: relative;
    display: block;
    font-family: var(--heading-font);
    font-size: 40px;
    letter-spacing: 3px;
    font-weight: 500;
    overflow: hidden;
    color: #fff;

    &.active {
      color: #fff;
    }

    button,
    a {
      font-size: var(--font-size-h3);
      letter-spacing: 3px;
      font-weight: 500;
      line-height: var(--font-size-h2);
      cursor: pointer;
      overflow: hidden;
      color: rgba(255, 255, 255, 0.68);
      position: relative;
      display: block;
      padding-left: 50px;
      transition-delay: 0.8s;
      transform: translateX(-10px) scale(0.8) rotate(1deg);
      opacity: 0;
      transform-origin: left;
      transition: opacity 0.5s ease, transform 0.5s ease;

      span {
        transition: 0.5s 0ms;
      }

      &:hover {
        span {
          color: #fff;
          letter-spacing: 2.5px;
          -webkit-transform: skewX(10deg);
          transform: skewX(10deg);
        }
      }
    }

    ul {
      display: none;
      margin-top: 15px;

      li {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 10px;
      }
    }
  }
}

.nav-content {
  $width: 40%;
  display: flex;
  align-items: center;

  @include media_768 {
    display: none;
  }

  .inner-content {
    width: 100%;

    .container-content {
      padding: 50px 0 0 0;
      text-align: left;
      position: relative;
      margin: 0;

      > div {
        &:after {
          background-color: rgba(233, 233, 233, 0.1);
          content: "";
          position: absolute;
          left: calc(50px * -1);
          top: 0;
          width: 1px;
          height: 100%;
        }
      }

      h5 {
        color: #a56433;
        font-size: 17px;
        font-weight: 600;
        margin-bottom: 10px;
        letter-spacing: 2px;
      }

      p {
        font-size: 16px;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.68);
      }

      a {
        color: #fff;
      }

      .nav-social {
        ul {
          li {
            color: var(--heading-color);
            display: inline-block;
            font-size: 14px;
            letter-spacing: 2px;
            margin-right: 15px;
            padding-right: 5px;
            position: relative;
          }
        }
      }
    }
  }

  @extend .nav;
  width: $width;
  right: 0;
  left: auto;

  &:before,
  &:after {
    content: "";
    position: fixed;
    width: $width;
    right: 0;
    left: auto;
    transform: translateX(100%) translateY(0%);
  }

  @include text-overlay-hide(1000, 1, address, div);

  address {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.7;
    overflow: inherit;
    color: inherit;
    width: 100%;
    text-align: left;

    span {
      display: block;
    }
  }
}

body.nav-active {
  overflow: hidden !important;
  $menu--items--count: 20;

  main.main-root {
    transform: scale(0.5, 1) translateX(30%);
    opacity: 0;
    visibility: hidden;
    transition: all 1s ease-in-out;

    @include media_768 {
      transform: scale(0.8);
      transition-delay: 0.1s;
    }
  }

  .header-top {
    .header-container {
      .menu-icon {
        .icon-circle {
          border: 2px solid #ccc;
        }

        .menu-icon-close {
          display: block;
          width: 22px;
        }
      }
    }
  }

  .nav {
    visibility: visible;
    opacity: 1;
    transition: all 0.2s ease-in-out 0.5s;

    &:before,
    &:after {
      transform: translateX(0%) translateY(0%);
    }

    &:after {
      transition-delay: 0.4s;
    }

    &:before {
      transition-delay: 0s;
    }

    .inner {
      opacity: 1;
      transition-delay: 0.8s;
    }

    &__content {
      opacity: 1;
      transition-delay: 0.8s;
    }

    &__list-item {
      margin-bottom: 15px;

      @for $i from 0 through $menu--items--count {
        &:nth-child(#{$i}) a,
        &:nth-child(#{$i}) button {
          transition-delay: calc(1.5 * $i / 10 + 1) + s;
        }
      }

      button,
      a {
        transform: translateX(0%);
        opacity: 1;
        transition: opacity 1s, transform 1.5s;
        transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
        width: max-content;
      }
    }

    @include text-overlay-show(1500, 5, address, div, 3);
  }
}

.btnChars {
  .char {
    animation-name: hidelistLink;
    animation-duration: 0.5s;
    animation-timing-function: cubic-bezier(0.7, 0.2, 0.9, 0.4);
    animation-fill-mode: forwards;
    animation-direction: alternate;
  }
}

ul.links-list-hide {
  > li {
    > button,
    > a {
      display: none !important;
    }
  }
}

.nav__list,
ul.show-group {
  display: flex !important;
  background-color: inherit;
  flex-flow: column;

  li {
    position: relative;

    button,
    a {
      animation-name: listLink;
      animation-duration: 0.7s;
      animation-timing-function: cubic-bezier(0.7, 0.2, 0.9, 0.4);
      animation-fill-mode: backwards;
      animation-direction: alternate;
    }
  }
}

ul.sub-list {
  flex-flow: column;
  justify-content: center;
  position: relative;
  background-color: inherit;
  display: none;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;

  li.back-item {
    button,
    a {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;

      &:hover {
        span {
          bottom: 120%;

          &::after {
            opacity: 1;
          }
        }
      }

      span {
        font-size: var(--font-size-h6) !important;
        letter-spacing: 0.1rem;

        &::after {
          content: "Back";
          padding-left: 20%;
          position: absolute;
          width: -moz-fit-content;
          width: -moz-available;
          top: 100%;
          left: 0;
          color: #fff;
          opacity: 0;
          text-align: left;
        }
      }
    }

    svg {
      position: relative;
      font-size: 20px;
      margin-right: 10px;
      top: 0px;
      color: rgba(233, 233, 233, 0.1);
    }

    .back-menu {
      color: #fff;
    }
  }
}

.btnChars {
  .char {
    opacity: 0;
  }
}

$maxElements: 10;

@for $i from 1 to $maxElements {
  .li-menu-#{$i} {
    > button,
    > a {
      animation-delay: (calc($i / 8) + 0.2) + s + " !important";
    }
  }
}

@for $i from 1 to $maxElements {
  .char-#{$i} {
    animation-delay: calc($i / 20) + s + " !important";
  }
}

@keyframes listLink {
  0% {
    opacity: 0;
    transform: translateY(-3px);
  }

  50% {
    opacity: 1;
    transform: translateY(3px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes hidelistLink {
  0% {
    opacity: 0;
    transform: translateY(6px);
  }

  50% {
    opacity: 1;
    transform: translateY(0px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
</style>
